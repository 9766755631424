<template>
  <div class="c19 doc-content"><p class="c29"><span class="c21 c7 c26">COOKIES POLICY</span></p>
    <p class="c5" id="h.gjdgxs"><span>Effective from: </span><span class="c21">17 </span><span
        class="c0">November 2023</span></p>
    <p class="c5"><span class="c3">1. Introduction</span></p>

    <p class="c5"><span>This Cookies Policy provides you with information about cookies we use and how to control them for our website </span><span
        class="c16"><a class="c6"
                       href="https://www.google.com/url?q=http://techss.co/&amp;sa=D&amp;source=editors&amp;ust=1700234793294619&amp;usg=AOvVaw2ep2CFsZdqZmlb5ILTa2b-">http://techss.co/</a></span><span
        class="c0">&nbsp;</span></p>
    <p class="c5"><span class="c3">2. About Cookies</span></p>
    <p class="c9"><span class="c0">Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises those cookies. Cookies are useful because they allow our website to recognise your device.</span>
    </p>
    <p class="c5"><span class="c3">3. What Cookies do we use?</span></p>
    <p class="c9"><span>Some cookies are allocated to your device only for the duration of your visit to a website, and these are called&nbsp;</span><span
        class="c7">&lsquo;session based&rsquo;</span><span class="c0">&nbsp;cookies. These cookies automatically expire at the end of the user session, when the web browser is closed.</span>
    </p>
    <p class="c9"><span>Another type of cookie known as&nbsp;</span><span class="c7">&lsquo;persistent cookies&rsquo;</span><span
        class="c0">&nbsp;consists of a text file sent by a web server to a web browser, which will be stored by the browser and will remain valid until its set expiry date (unless deleted by the user before the expiry date).</span>
    </p>
    <p class="c9"><span class="c0">Our website may use five types of cookies: strictly necessary cookies, functionality cookies, analytics cookies, targeting cookies or third party websites&rsquo; cookies.</span>
    </p>
    <p class="c9"><span class="c0">The list of specific cookies that we use on our website, typical parameters, storage period etc. is available below:</span>
    </p>
    <p class="c9"><span class="c7">1) Strictly Necessary Cookies</span><span class="c0">&nbsp;&ndash; these cookies are essential in order to enable you to move around our website and use its features, such as accessing secure areas of our website. These temporary cookies last only as long as your web browser is open and are used for technical purposes such as enabling better navigation on our website. The website may not function properly without these cookies. More information about Strictly Necessary Cookies is available in the table below:</span>
    </p><a id="t.2b75b8ee6a2ab24ae9590859a345b770013804a1"></a><a id="t.0"></a>
    <table class="c1">
      <tr class="c8">
        <td class="c18" colspan="1" rowspan="1"><p class="c5"><span class="c3">Type of Cookie</span></p></td>
        <td class="c13" colspan="1" rowspan="1"><p class="c5"><span class="c3">Expiry</span></p></td>
      </tr>
      <tr class="c8">
        <td class="c15" colspan="1" rowspan="1"><p class="c5"><span class="c3">Session cookie</span></p></td>
        <td class="c30" colspan="1" rowspan="1"><p class="c5"><span class="c0">Session</span></p></td>
      </tr>
      <tr class="c8">
        <td class="c15" colspan="1" rowspan="1"><p class="c5"><span class="c3">Persistent cookie</span></p></td>
        <td class="c30" colspan="1" rowspan="1"><p class="c5"><span class="c0">1 month</span></p></td>
      </tr>
    </table>
    <p class="c5 c10"><span class="c3"></span></p>
    <p class="c9"><span class="c7">2) Functionality</span><span class="c0">&nbsp;&ndash; these cookies allow our website to remember choices you make (e.g. your user name, language or the region you are in), so that our website can offer you improved and more personalized functions.</span>
    </p>
    <p class="c5"><span class="c0">More information about Functionality Cookies is available in the table below:</span></p>
    <p class="c5 c10"><span class="c0"></span></p><a id="ee9b10c10ab2684afc8ea08f365e9371ee11f697"></a><a id="t.1"></a>
    <table class="c1">
      <tr class="c8">
        <td class="c11" colspan="1" rowspan="1"><p class="c5"><span class="c3">Type of Cookie</span></p></td>
        <td class="c23" colspan="1" rowspan="1"><p class="c5"><span class="c3">Expiry</span></p></td>
      </tr>
      <tr class="c8">
        <td class="c25" colspan="1" rowspan="1"><p class="c5"><span class="c3">Persistent cookie</span></p></td>
        <td class="c17" colspan="1" rowspan="1"><p class="c5"><span class="c0">1 year</span></p></td>
      </tr>
    </table>
    <p class="c5 c10"><span class="c3"></span></p>
    <p class="c5"><span class="c7">3) Analytics</span><span class="c0">&nbsp;&ndash; these cookies analyse how our website is being accessed, used, or is performing in order to provide you with a better user experience and to maintain, operate and continually improve our website. Analytics cookies allow us to better understand our website visitors so that we can improve how we present our content or to improve our website by measuring any errors that occur.</span>
    </p>
    <p class="c5"><span class="c0">More information about Analytics Cookies is available in the table below:</span></p><a
        id="t.ee9b10c10ab2684afc8ea08f365e9371ee11f697"></a><a id="t.2"></a>
    <table class="c1">
      <tr class="c8">
        <td class="c28" colspan="1" rowspan="1"><p class="c5"><span class="c3">Type of Cookie</span></p></td>
        <td class="c24" colspan="1" rowspan="1"><p class="c5"><span class="c3">Expiry</span></p></td>
      </tr>
      <tr class="c8">
        <td class="c20" colspan="1" rowspan="1"><p class="c5"><span class="c3">Persistent cookie</span></p></td>
        <td class="c12" colspan="1" rowspan="1"><p class="c5"><span class="c0">1 year</span></p></td>
      </tr>
    </table>
    <p class="c5 c10"><span class="c3"></span></p>
    <p class="c22"><span class="c7">4) Targeting</span><span class="c0">&nbsp;&ndash; these cookies are used to deliver adverts that are relevant to you and your interests.</span>
    </p>
    <p class="c22"><span class="c0">More information about Targeting Cookies is available in the table below:</span></p><a
        id="t.e6e754b9fce04d4df9c82ef41ce2e54b6498efdb"></a><a id="t.3"></a>
    <table class="c1">
      <tr class="c8">
        <td class="c28" colspan="1" rowspan="1"><p class="c5"><span class="c3">Type of Cookie</span></p></td>
        <td class="c24" colspan="1" rowspan="1"><p class="c5"><span class="c3">Expiry</span></p></td>
      </tr>
      <tr class="c8">
        <td class="c20" colspan="1" rowspan="1"><p class="c5"><span class="c3">Persistent cookie</span></p></td>
        <td class="c12" colspan="1" rowspan="1"><p class="c5"><span class="c0">1 month</span></p></td>
      </tr>
    </table>
    <p class="c5 c10"><span class="c3"></span></p>
    <p class="c9"><span class="c7">5) Third Party Websites&rsquo; Cookies</span><span class="c0">&nbsp;&ndash; we may use third-party service providers to assist us by providing certain statistical and analytics information. Third Party Websites&rsquo; Cookies are set by someone other than us for purposes such as e.g. collecting information on your behavior, demographics, or personalized marketing.</span>
    </p>
    <p class="c5"><span class="c0">This may be a web analytics service provided by Google Analytics that assists us in analyzing the use of our Website. Google Analytics uses cookies for this purpose. </span>
    </p>
    <p class="c9">
      <span>For more detailed information on Google Analytics data processing activities, please go to&nbsp;</span><span
        class="c16"><a class="c6"
                       href="https://www.google.com/url?q=https://marketingplatform.google.com/about/analytics/terms/gb/&amp;sa=D&amp;source=editors&amp;ust=1700234793301617&amp;usg=AOvVaw3KAeRETA49H4gfufZUGrHz">https://marketingplatform.google.com/about/analytics/terms/gb/</a></span><span
        class="c0">. </span></p>
    <p class="c5"><span class="c0">We do not have control over the placement of cookies by other third-party websites, even if you are directed to them from our Website.</span>
    </p>
    <p class="c5"><span class="c3">6. How do we obtain your Personal Data?</span></p>
    <p class="c9"><span class="c0">We collect information you provide directly to us. For example, when becoming a new client (if you have entered into or seek to enter into an agreement with us). The Company also collects information which you provide to us, such as messages that you have sent to us, by access and use of our website or mobile application, by setting up an account with us, when you subscribe to our electronic publications (e.g. newsletters).</span>
    </p>
    <p class="c9"><span class="c0">Personal Data that we may collect from third parties. There are number of ways to manage cookies. You can set your consent or if you wish you can control or delete the placing of cookies on your computer. If you delete the cookies, any settings and preferences controlled by those cookies will be deleted and will need to be re-created when you visit our website subsequently. We have explained how you can exercise this right below:</span>
    </p>
    <ul class="c14 lst-kix_list_1-0 start">
      <li class="c2 li-bullet-0"><span class="c0">Chrome: </span><span class="c4"><a class="c6"
                                                                                     href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1700234793302436&amp;usg=AOvVaw2FP0Oib0myOH8-Krvr4L20">https://support.google.com/chrome/answer/95647?hl=en</a></span><span
          class="c0">&nbsp;</span></li>
      <li class="c2 li-bullet-0"><span class="c0">Explorer: </span><span class="c4"><a class="c6"
                                                                                       href="https://www.google.com/url?q=https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1700234793302764&amp;usg=AOvVaw16SA9_jq1lIUklR12Y9crK">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a></span><span
          class="c0">&nbsp; </span></li>
      <li class="c2 li-bullet-0"><span class="c0">Safari: </span><span class="c4"><a class="c6"
                                                                                     href="https://www.google.com/url?q=https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1700234793303041&amp;usg=AOvVaw1eFEosqUS6-oEQ-8BE2Qzr">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></span>
      </li>
      <li class="c2 li-bullet-0"><span class="c0">Firefox: </span><span class="c4"><a class="c6"
                                                                                      href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer&amp;sa=D&amp;source=editors&amp;ust=1700234793303337&amp;usg=AOvVaw0jnvKCljDCtMzU3wRNck-z">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer</a></span><span
          class="c0">&nbsp; &nbsp;</span></li>
      <li class="c5 c27 li-bullet-0"><span class="c0">Opera: </span><span class="c4"><a class="c6"
                                                                                        href="https://www.google.com/url?q=https://help.opera.com/en/latest/web-preferences/%23cookies&amp;sa=D&amp;source=editors&amp;ust=1700234793303588&amp;usg=AOvVaw1Bf_OrGUcFGwUiddmtwWWf">https://help.opera.com/en/latest/web-preferences/#cookies</a></span>
      </li>
    </ul>
    <p class="c5"><span class="c7">1) Consent Tool</span></p>
    <p class="c9"><span class="c0">Our Cookie Consent Tool may be utilized to customize your cookie preferences. The tool will record when you have consented to our cookie policy and will ask for consent again annually to ensure users stay up-to-date with changes to our cookie and privacy policies. The consent tool specifically controls the marketing cookies and analytical cookies set by using our website. Essential cookies cannot be disabled, nor can the tool be used to block cookies on third-party websites linked from our website.</span>
    </p>
    <p class="c5"><span class="c7">2) Using Your Browser</span></p>
    <p class="c9"><span class="c0">Most browsers allow you to change your cookies settings. Many of the cookies used on our website and through emails can be enabled or disabled through our consent tool or by disabling the cookies through your browser. However, if you set your browser to block essential cookies, some parts of our website will not work. In order to understand those settings, the following links may be helpful, otherwise you should use the &lsquo;Help&rsquo; option in your browser for more details.</span>
    </p>
    <p class="c5"><span class="c3">7. Changes and Updates to this Cookies Policy</span></p>
    <p class="c9"><span class="c0">We will occasionally update this Cookies Policy to reflect changes in our practices and services. When we post changes to this Cookies Policy, we will revise the &lsquo;Last Updated&rsquo; date at the top of this Cookies Policy. If we make any material changes in the way we collect, use, and/or share information held in cookies, we will notify you by prominently posting notice of the changes on our website. We recommend that you check this page occasionally to inform yourself of any changes in this Cookies Policy.</span>
    </p>
    <p class="c5"><span class="c3">8. Contact us</span></p>
    <p class="c9"><span>Any questions, comments and requests regarding this Cookies Policy should be addressed by sending an e-mail to: </span><span
        class="c21"></span><span></span><span
        class="c16"><a class="c6" href="mailto:info@techss.co">info@techss.co</a></span><span class="c0">&nbsp;</span>
    </p></div>
</template>

<script setup>

</script>

<style lang="stylus" scoped>

.lst-kix_list_1-3 > li:before {
  content: "\0025cf   "
}

.lst-kix_list_1-4 > li:before {
  content: "o  "
}

ul.lst-kix_list_1-0 {
  list-style-type: none
}

.lst-kix_list_1-7 > li:before {
  content: "o  "
}

.lst-kix_list_1-5 > li:before {
  content: "\0025aa   "
}

.lst-kix_list_1-6 > li:before {
  content: "\0025cf   "
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt
}

ul.lst-kix_list_1-3 {
  list-style-type: none
}

.lst-kix_list_1-0 > li:before {
  content: "\0025cf   "
}

ul.lst-kix_list_1-4 {
  list-style-type: none
}

.lst-kix_list_1-8 > li:before {
  content: "\0025aa   "
}

ul.lst-kix_list_1-1 {
  list-style-type: none
}

ul.lst-kix_list_1-2 {
  list-style-type: none
}

ul.lst-kix_list_1-7 {
  list-style-type: none
}

.lst-kix_list_1-1 > li:before {
  content: "o  "
}

.lst-kix_list_1-2 > li:before {
  content: "\0025aa   "
}

ul.lst-kix_list_1-8 {
  list-style-type: none
}

ul.lst-kix_list_1-5 {
  list-style-type: none
}

ul.lst-kix_list_1-6 {
  list-style-type: none
}

ol {
  margin: 0;
  padding: 0
}

table td, table th {
  padding: 0
}

.c20 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #ffffff;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 247.8pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c12 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #ffffff;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 233.6pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c28 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #EFFFF2;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 247.8pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c17 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #ffffff;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 230.8pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c18 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #EFFFF2;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 225.9pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c25 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #ffffff;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 250.7pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c11 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #EFFFF2;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 250.7pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c30 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #ffffff;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 255.6pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c13 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #EFFFF2;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 255.6pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c24 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #EFFFF2;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 233.6pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c15 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #ffffff;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 225.9pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c23 {
  border-right-style: solid;
  padding: 2.9pt 41.2pt 2.9pt 41.2pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: bottom;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #EFFFF2;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 230.8pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c4 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: 'Inter', sans-serif;
  font-style: normal
}

.c2 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Inter', sans-serif;
  font-style: normal
}

.c3 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Inter', sans-serif;
  font-style: normal
}

.c29 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: center
}

.c9 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify
}

.c5 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c22 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: justify;
  margin-bottom 20rem;
}

.c26 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Inter', sans-serif;
  font-style: normal
}

.c16 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  text-decoration: underline
}

.c1 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
  width 100%;
}

.c19 {
  background-color: #ffffff;
  max-width: 960rem;
  padding: 0;
  position relative;
  top 0;
  left 50%;
  transform translate(-50%);
  margin-top 30rem;
}

.c6 {
  color: inherit;
  text-decoration: inherit
}

.c14 {
  padding: 0;
  margin: 0
}

.c27 {
  margin-left: 36pt;
  padding-left: 0pt
}

.c21 {
  color: #000000
}

.c8 {
  height: 0pt
}

.c7 {
  font-weight: 700
}

.c10 {
  height: 11pt
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Inter', sans-serif;
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Inter', sans-serif;
}

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

a {
  color #00c853!important
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}

@media (max-width: 960px) {
  .c19 {
    width 90%
    left 5%
    transform none
    padding 0
  }
}

</style>
